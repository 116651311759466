import 'survey-react/survey.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import classes from './Survey.module.css';
import * as Survey from 'survey-react';
import Json from './surveyDefinition';
import axios from "axios";
import moment from "moment";
import React, { useState, useEffect } from 'react';

const MySurvey = () => {
      
  const postReview = (data) => {
    data.subject = data.subject.replace(/ /g, '').toUpperCase()
    data.courseId = data.subject + data.courseNumber
    data.courseId = data.courseId.replace(/ /g, '').toUpperCase()
    data.courseName = data.courseName.trim().toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
    data.instructor = data.instructor ? data.instructor.trim().toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()) : ''
    data.timestamp = moment().utc()
    data.helpful = 0
    data.report = 0
    axios
      .post("https://site--classreview-service--classreview-backend--bwgb-7bs6.code.run/api/add/review", data)
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container">
      <div className={classes.container}>
        <Survey.Survey
          css={Survey.StylesManager.applyTheme("darkblue")}

          onComplete={data => postReview(data.valuesHash)}
          json={Json}
        />
      </div>
    </div>
  )
}

export default MySurvey;
