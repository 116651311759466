//import React, { useContext } from 'react';
import { Link } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import "jquery/dist/jquery"
//import CollegeContext from './CollegeContext';
import { Search } from 'react-bootstrap-icons';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom'
import classes from './navbar.module.css';
import React, { useState, useEffect } from 'react';

const Navbar = () => {

  const [stateSearchParams, setSearchParamsState] = useState([]);
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search).get('query');

  const { register, handleSubmit } = useForm();

  const onSubmit = (data) => {
    const urlParams = data.query.replace(/ /g, '+');
    if (urlParams === "") {
      setSearchParamsState([]);
      history.push({
        pathname: '/arizona-state-university/reviews',
      })
    } else {
    window.location.href=`/arizona-state-university/reviews?query=${urlParams}`
    }
    
    window.scrollTo(0, 0);
  }

  const resetSearchParams = () =>{
    setSearchParamsState([]);
  }

  useEffect(() => {
    setSearchParamsState(searchParams);
  }, []);

  return (
    <nav className="navbar sticky-top navbar-expand-lg navbar-dark" style={{ backgroundColor: "#8C1D40" }}>
      <a className="navbar-brand" >Arizona State University</a>
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
        <div className="navbar-nav">
          <Link to={`/arizona-state-university/reviews`} className="nav-item nav-link">
            <span onClick={() => resetSearchParams()}>
              Reviews
            </span>
          </Link>
          <Link to={`/arizona-state-university/summary`} className="nav-item nav-link">Summary</Link>
          <Link to={`/arizona-state-university/submit-review`} className="nav-item nav-link">Submit review</Link>
          <Link to={`/arizona-state-university/contact-us`} className="nav-item nav-link  mr-5">Contact us</Link>

          <form className="form-inline my-2 my-lg-0" onSubmit={handleSubmit(onSubmit)}>
          
          <div className="input-group form-horizontal">
          
          <input defaultValue={stateSearchParams} style={{width : '15em'}} ref={register} minLength={3} className={`form-control input-lg shadow-none container-fluid ${classes.search_bar}`} id="searchfield" type="search" name="query" placeholder="Search" aria-label="keyword" />
          
          <div className="btn-group" role="group">
              <button className={classes.search_button} type="submit"><Search /></button>
              {/* <button className="btn btn-outline-secondary btn-sm rounded-0" type="reset" onClick={reset}>Reset</button> */}
              
          </div>
          </div>
          </form>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;