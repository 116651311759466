import axios from 'axios';
import React, { useState, useEffect } from 'react';
import classes from './ViewCourses.module.css';
import Rating from '@mui/material/Rating';
import { HandThumbsUp } from 'react-bootstrap-icons';
import moment from 'moment'
import cookies from 'js-cookie'
import * as ReactBootStrap from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import { Tooltip, Button } from '@mui/material';
//import { useHistory } from 'react-router-dom';

const ViewCourses = () => {

  const [stateReview, setReviewState] = useState([]);
  const [loading, setloading] = useState(false);
  const [infiniteSrollLoading, setinfiniteSrollLoading] = useState(true);

  const { search } = useLocation()
  const searchParams = new URLSearchParams(search).get('query')

  let page = 0;
  let lastPage = false;

  const getAllReviews = async () => {
    try {
      await axios
        .get(`https://site--classreview-service--classreview-backend--bwgb-7bs6.code.run/api/reviewSlice?size=20&page=${page}`)
        .then(response => {
          response.data.content.map(function (d) {
            if (d["timestamp"] != null) {
              d["timestamp"] = moment(d["timestamp"]).local().format("MM-DD-YYYY HH:mm:ss");
            }
          });
          setReviewState(prevResponse => [...prevResponse, ...response.data.content]);
          if (response.data.last === true) { lastPage = true }
        });
      page += 1;
      setloading(true);
      if (lastPage === true) { setinfiniteSrollLoading(false) };
    } catch (e) {
      console.log(e);
    }
  };

  const getClassReview = async (keyword) => {
    try {
      await axios
        .get(`https://site--classreview-service--classreview-backend--bwgb-7bs6.code.run/api/searchBySlice/${keyword}?size=20&page=${page}`)
        .then(response => {
          response.data.content.map(function (d) {
            if (d["timestamp"] != null) {
              d["timestamp"] = moment(d["timestamp"]).local().format("MM-DD-YYYY HH:mm:ss");
            }
          });
          setReviewState(prevResponse => [...prevResponse, ...response.data.content]);
          if (response.data.last === true) { lastPage = true };
        });
      page += 1;
      setloading(true);
      if (lastPage === true) { setinfiniteSrollLoading(false) };
    } catch (e) {
      console.log(e);
    }
  };

  const addHelpful = (id) => {
    axios
      .patch(`https://site--classreview-service--classreview-backend--bwgb-7bs6.code.run/api/addHelpful/${id}`);
    cookies.set(`rated${id}`, 'true', { expires: 365 });

    const element = document.createElement('span');
    const textnode = document.createTextNode("Thank you for your feedback.");
    element.setAttribute("style", "color: green;");
    element.appendChild(textnode);
    document.getElementById(id).parentNode.replaceWith(element);

  };

  const reportAbuse = (id) => {
    axios
      .patch(`https://site--classreview-service--classreview-backend--bwgb-7bs6.code.run/api/addReport/${id}`);
    cookies.set(`rated${id}`, 'true', { expires: 365 });

    const element = document.createElement('span');
    const textnode = document.createTextNode("Thank you for your feedback.");
    element.setAttribute("style", "color: green;");
    element.appendChild(textnode);
    document.getElementById(id).parentNode.replaceWith(element);

  };

  var flag;

  const handleScroll = (e) => {
    if (flag) return;
    flag = true;
    if (flag) {
      if (
        window.innerHeight + e.target.documentElement.scrollTop + 1 >=
        e.target.documentElement.scrollHeight
      ) {
        if (lastPage === false) { setinfiniteSrollLoading(true) } else { setinfiniteSrollLoading(false) };
        if (searchParams !== null) {
          getClassReview(searchParams);
        } else {
          getAllReviews();
        }
      }
    }
    flag = false;
  }

  useEffect(() => {

    if (searchParams !== null) {
      getClassReview(searchParams);

    } else {
      setloading(false);
      setReviewState([]);
      getAllReviews();
    };
    window.addEventListener('scroll', handleScroll);
    window.scrollTo(0, 0);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [searchParams]);

  return (
    <div>

      {loading ?

        <div>

          {stateReview.map(data => (
            <div className={classes.container_1}>
              <div className={classes.container_3}>
                <div><h6><b>{data.subject}{data.courseNumber} {data.courseName}</b></h6></div>
              </div>
              <div className={classes.container_2}>
                <div>Semester: <b>{data.semester} {data.year}</b></div>
                <div>Location: <b>{data.location}</b></div>
                {data.instructor ?
                  <div>Instructor: <b>{data.instructor}</b></div> : (<p></p>)}
                {data.grade ?
                  <div>Grade: <b>{data.grade}</b></div> : (<p></p>)}
                <div>Recommendation: <b>{data.recommendation}</b></div>
              </div>
              <div className={classes.container_2}>
                <Tooltip title="5 stars being the best quality">
                  <div>

                    <span className={classes.tooltip}>
                      Quality:
                    </span>

                    <Rating value={data.quality} readOnly={true} />
                  </div>
                </Tooltip>
                <Tooltip title="5 stars being the most useful">
                  <div>

                    <span className={classes.tooltip}>
                      Usefulness:
                    </span>

                    <Rating value={data.usefulness} readOnly={true} /></div></Tooltip>
                <Tooltip title="5 stars being the most difficult">
                  <div>

                    <span className={classes.tooltip}>
                      Difficulty:
                    </span>

                    <Rating value={data.difficulty} readOnly={true} /></div></Tooltip>
                <Tooltip title="5 stars being the highest workload">
                  <div>

                    <span className={classes.tooltip}>
                      Workload:
                    </span>

                    <Rating value={data.workload} readOnly={true} /></div></Tooltip>
              </div>
              <div className={classes.discription_container}>
                <div>
                  {data.generalfeedback ? data.generalfeedback.split ('\n').map ((item, i) => <p key={i}>{item}</p>) : ""}
                  {data.tips ?
                    (<p>Tips: {data.tips}</p>) : (<p></p>)}
                  <div>
                    <p className="text-muted"><small>{data.timestamp}</small></p>
                    {data.helpful > 0 ?
                      (<p className="text-muted"><small>{data.helpful} people found this review helpful</small></p>) : (<p></p>)}
                    <div key={data.reviewId}>
                      <div id={data.reviewId}>
                        {cookies.get(`rated${data.reviewId}`) ?
                          (<p></p>) : (<div><button onClick={() => addHelpful(data.reviewId)} className="btn btn-outline-secondary btn-sm"><small><HandThumbsUp /> Helpful</small></button>
                            <span className='text-muted'>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                            <span className='text-muted'></span><button onClick={() => reportAbuse(data.reviewId)} className={classes.astext}>Report abuse</button>
                          </div>)}
                      </div>
                    </div>

                  </div>
                </div>
              </div>

            </div>

          )
          )}

          {stateReview.length > 0 ?
            (<div></div>) : (<div className="m-1">No reviews found.</div>)}
        </div>
        :
        <div className="text-center m-5">
          <ReactBootStrap.Spinner animation="border" />
        </div>
      }
      {stateReview.length > 0 ?
        (<div className="text-center m-5">
          {infiniteSrollLoading && <ReactBootStrap.Spinner animation="border" />}
        </div>) : (<div></div>)}

    </div>

  )
}

export default ViewCourses;