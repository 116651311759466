const Json =
{
   logoPosition: "right",
   pages: [
    {
     name: "Survey",
     elements: [
      {
       type: "text",
       name: "subject",
       title: "Subject",
       titleLocation: "left",
       hideNumber: true,
       isRequired: true,
       placeHolder: "e.g. CSE"
      },
      {
       type: "text",
       name: "courseNumber",
       title: "Number",
       titleLocation: "left",
       hideNumber: true,
       isRequired: true,
       inputType: "number",
       placeHolder: "e.g. 551",
       startWithNewLine: "false"
      },
      {
       type: "text",
       name: "courseName",
       minWidth: "",
       title: "Full course title",
       titleLocation: "left",
       hideNumber: true,
       isRequired: true,
       placeHolder: "e.g. Foundations of Algorithms"
      },

      {
       type: "dropdown",
       name: "location",
       title: "Location",
       titleLocation: "left",
       hideNumber: true,
       isRequired: true,
       choices: [
        "On-campus",
        "Online"
       ]
      },
      {
       type: "dropdown",
       name: "semester",
       title: "Semester",
       titleLocation: "left",
       hideNumber: true,
       isRequired: true,
       choices: [
        "Spring",
        "Summer",
        "Fall"
       ]
      },
      {
       type: "text",
       name: "year",
       startWithNewLine: false,
       title: "Year",
       titleLocation: "left",
       hideNumber: true,
       isRequired: true,
       validators: [
        {
         type: "numeric",
         minValue: 2015,
         maxValue: 2999
        }
       ],
       inputType: "number",
       step: 0,
       maxLength: ""
      },
      {
        type: "text",
        name: "instructor",
        title: "Instructor",
        titleLocation: "left",
        hideNumber: true,
        placeHolder: "the one on your schedule"
       },
      {
       type: "dropdown",
       name: "grade",
       title: "Grade",
       titleLocation: "left",
       hideNumber: true,
       isRequired: false,
       choices: [
        "A+",
        "A",
        "A-",
        "B+",
        "B",
        "B-",
        "C+",
        "C",
        "D",
        "E",
        "EN",
        "EU",
        "I",
        "NR",
        "P",
        "W",
        "X",
        "Y",
        "Z",
        "XE",
        {
         value: "PASS",
         text: "Pass"
        },
        {
         value: "FAIL",
         text: "Fail"
        }
       ]
      },
      {
       type: "rating",
       name: "quality",
       title: "Overall Quality",
       titleLocation: "left",
       description: "5 being the best qaulity",
       hideNumber: true,
       isRequired: true
      },
      {
       type: "rating",
       name: "usefulness",
       title: "Usefulness",
       titleLocation: "left",
       description: "5 being the most useful",
       hideNumber: true,
       isRequired: true
      },
      {
       type: "rating",
       name: "workload",
       title: "Workload",
       titleLocation: "left",
       description: "5 being the highest workload",
       hideNumber: true,
       isRequired: true
      },
      {
       type: "rating",
       name: "difficulty",
       title: "Difficulty",
       titleLocation: "left",
       description: "5 being the most difficult",
       hideNumber: true,
       isRequired: true
      },
      {
       type: "comment",
       name: "generalfeedback",
       title: "Class feedback",
       hideNumber: true,
       isRequired: true,
       rows: 6,
       placeHolder: "What do you like or dislike? What else do you want to talk about?"
      },
      {
       type: "comment",
       name: "tips",
       title: "Any tips for other students?",
       hideNumber: true
      },
      {
       type: "radiogroup",
       name: "recommendation",
       title: "Would you recommend this class to other students?",
       hideNumber: true,
       isRequired: true,
       choices: [
        {
         value: "YES",
         text: "Yes"
        },
        {
         value: "NO",
         text: "No"
        },
        "Maybe"
       ]
      }
     ]
    }
   ]
  }

export default Json;