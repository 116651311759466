import classes from './Contactus.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import emailjs from 'emailjs-com';

export default function ContactUs() {

  function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm('service_0bl76r3', 'template_w2187rr', e.target, 'lYOSnQBM29dHQ1xNw')
      .then((result) => {
        console.log(result.text);
        alert('Your message has been sent.');
      }, (error) => {
        console.log(error.text);
      });
    e.target.reset()
  }

  return (
    <div className={classes.main_container}>
      <div className="container">
        <div class="text-muted">
          Email:
        </div>
        <div class="text-muted">
          kluencheng@gmail.com
        </div>
        <small class="text-muted">
          *please specify it's related to classreview.org in the subject
        </small>
        {/*
          <form onSubmit={sendEmail}>
            <small class="text-muted">
              <div className="form-group">
                <label>Name *</label>
                <input name="name" className="form-control form-control-sm" required />
              </div>
              <div className="form-group">
                <label>Email *</label>
                <input name="email" type="email" className="form-control form-control-sm" required />
              </div>
              <div className="form-group">
                <label>Subject *</label>
                <input name="subject" className="form-control form-control-sm" required />
              </div>
  
              <div className="form-group">
                <label>Message *</label>
                <textarea name="message" className="form-control form-control-sm" rows="6" required />
              </div>
              <input type="submit" value="Send" className="btn btn-secondary btn-sm" />
            </small>
  
          </form>
        */}
      </div>
    </div>
  );
}
