import './App.css';
import MySurvey from './components/surveyType';
import ViewCourses from './components/viewCourses';
import Navbar from './components/navbar';
import ContactUs from './components/contactUs';
import Summary from './components/summary';
import HomePage from './components/homePage';
import { BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import CollegeContext from './components/CollegeContext';
import React, { useState, useEffect } from 'react';

function App() {

  const [college, setCollege] = useState('Testing')

  return (
    <CollegeContext.Provider value={college}>
    <div className="App">
    <Router>
      <Switch>
          <>
            <Navbar />
              <Route exact path="/" component={ViewCourses} />
              <Route path='/arizona-state-university/submit-review' component={MySurvey} />
              <Route path='/arizona-state-university/reviews' component={ViewCourses} />
              <Route path='/arizona-state-university/contact-us' component={ContactUs} />
              <Route path='/arizona-state-university/summary' component={Summary} />
          </>
      </Switch>
    </Router>
    </div>
    </CollegeContext.Provider>
  )
}

export default App;
