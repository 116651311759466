import axios from "axios"
import * as React from 'react';
import { useState, useEffect, } from "react"
import 'bootstrap/dist/css/bootstrap.min.css'
import { makeStyles } from '@material-ui/styles';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import * as ReactBootStrap from 'react-bootstrap'

const Summary = () => {

  const [stateSummary, setSummaryState] = useState([]);
  const [loading, setloading] = useState(false);
  
  const getSummary = async () => {
    try {
    await axios
      .get("https://site--classreview-service--classreview-backend--bwgb-7bs6.code.run/api/summary")
      .then(response => {
        setSummaryState(response.data);
      });
      setloading(true)
    } catch (e) {
      console.log(e);
    }
  };
  
  useEffect(() => {
    getSummary();
  }, []);

  function getCourseId(params) {
    return `${params.row.subject || ''}${params.row.courseNumber || ''}`;
  }

  const columns = [
    { field: 'courseId', headerName: 'Id', width: 80, headerClassName: 'header', valueGetter: getCourseId },
    { field: 'courseName', headerName: 'Title', width: 350, headerClassName: 'header' },
    { field: 'countReview', headerName: 'Review Count', width: 120, filterable: false, headerClassName: 'header' },
    { field: 'avgQuality', headerName: 'Quality (1-5)', width: 110, filterable: false, headerClassName: 'header', description: '5 being the best quality' },
    { field: 'avgUsefulness', headerName: 'Usefulness (1-5)', width: 140, filterable: false, headerClassName: 'header', description: '5 being the most useful' },
    { field: 'avgDifficulty', headerName: 'Difficulty (1-5)', width: 120, filterable: false, headerClassName: 'header', description: '5 being the most difficult' },
    { field: 'avgWorkload', headerName: 'Workload (1-5)', width: 130, filterable: false, headerClassName: 'header', description: '5 being the highest workload' },
    { field: 'grade', headerName: 'Grade', width: 130, filterable: false, headerClassName: 'header' },
    { field: 'recommendation', headerName: 'Recommendation', width: 140, filterable: false, headerClassName: 'header' }
  ]

  const useStyles = makeStyles({
    root: {
      fontSize: 13,
      "& .MuiDataGrid-row": {
        cursor: "pointer",
      },
      '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
        outline: 'none',
      },
      '& .MuiDataGrid-iconSeparator': {
        display: 'none',
      },
      '& .header': {
        color: 'grey',
        padding: 0,
        border: 0,
        margin: 0,
        cursor: "pointer",
      },
    },
  });

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
      </GridToolbarContainer>
    );
  }

  const [pageSize, setPageSize] = React.useState(25);

  const handlePageSizeChange = (params) => {
    setPageSize(params.pageSize);
  };

  const handleOnRowClick = (row) => {
    console.log(row);
    window.open(`/arizona-state-university/reviews?query=${row}`)
    
  }

  const classes = useStyles();

  return (
    <div>
    {loading ?
    <div style={{ display: 'flex', height: 520, width: '100%', }}  >
      <DataGrid
      
        className={classes.root}
        sortingOrder={['desc', 'asc']}
        getRowId={(r) => r.courseName}
        rows={stateSummary}
        columns={columns}
        rowHeight={27}
        disableColumnMenu={true}
        components={{
          Toolbar: CustomToolbar,
        }}
        autoHeight={false}
        headerHeight={30}

        pageSize={100}
        onPageSizeChange={handlePageSizeChange}
        autoPageSize={false}
        hideFooterSelectedRowCount={true}
        rowsPerPageOptions={[100, 150, 200]}
        onRowClick={(rowData) => handleOnRowClick(rowData.row.courseName)}
      />
    </div> : 
    <div className="text-center m-5">
    <ReactBootStrap.Spinner animation="border" />
    </div>}
    </div>
  );
}

export default Summary;